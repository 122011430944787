import { TeamSettingsService } from '../../../../../../app/core/service/team-settings.service';
import { DestinationTypeConfigHelper } from '../../../../../../app/nodes/destination-settings-form/destination-type-config-helper';
import {
  DEFAULT_SNOWFLAKE_SCHEMA_NAME,
  SNOWFLAKE_DEFAULT_REGION_ID,
  SNOWFLAKE_HOST_URL_PREFIX,
  SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT,
  SNOWFLAKE_HOST_URL_SUFFIX,
  SnowflakeAuthenticationType
} from './constants';
import { extractAccountAndRegionFromSnowflakeHostURL } from './utils';
import { SnowflakeAutoFillExperimentService } from '../../../../../../app/nodes/nodes-settings/experiments';
import DestinationsAPI from '../../../DestinationsAPI';

export class SnowflakeConfigHelper implements DestinationTypeConfigHelper {
  constructor(
    private _snowflakeAutoFillExperimentService: SnowflakeAutoFillExperimentService,
    private _teamSettingsService: TeamSettingsService
  ) {}

  getDefaultConfig() {
    const enableKeyPairAuth =
      !!this._teamSettingsService.getTeamSettingsValue()?.sf_enable_key_pair_auth;

    const authType = enableKeyPairAuth
      ? SnowflakeAuthenticationType.KEY_PAIR
      : SnowflakeAuthenticationType.ACCESS_CREDENTIALS;

    if (this._snowflakeAutoFillExperimentService.isExperimentVariant()) {
      return {
        sanitizeName: true,
        isPrivateLink: false,
        authType
      };
    }

    return {
      schemaName: DEFAULT_SNOWFLAKE_SCHEMA_NAME,
      sanitizeName: true,
      isPrivateLink: false,
      authType
    };
  }

  getConfigFromRawData(rawData: any) {
    const privateLinkComponent = rawData.is_private_link
      ? `.${SNOWFLAKE_HOST_URL_PRIVATE_LINK_FRAGMENT}`
      : '';
    const accountUrl =
      rawData.account_name && rawData.region_id
        ? `${SNOWFLAKE_HOST_URL_PREFIX}${rawData.account_name}.${rawData.region_id}${privateLinkComponent}${SNOWFLAKE_HOST_URL_SUFFIX}`
        : undefined;

    const config: any = {
      user: rawData.db_user,
      password: rawData.db_password,
      passphrase: rawData?.private_key_passphrase || '',
      databaseName: rawData.db_name,
      schemaName: rawData.schema_name,
      warehouseName: rawData.warehouse,
      isTableTypeTransient: rawData.is_table_type_transient,
      sanitizeName: rawData.sanitize_name,
      accountUrl,
      accountName: rawData.account_name,
      region: rawData.region_id,
      isPrivateLink: rawData.is_private_link,
      authType: !!rawData.private_key || rawData.private_key === ''
        ? SnowflakeAuthenticationType.KEY_PAIR
        : SnowflakeAuthenticationType.ACCESS_CREDENTIALS
    };

    if ('private_key' in rawData && rawData.private_key !== '') {
      config.privateKey = { content: rawData?.private_key };
    }

    return config;
  }

  buildConnectionParams(data: any) {
    const params: any = {
      db_user: data.user,
      db_name: data.databaseName,
      warehouse: data.warehouseName,
      sanitize_name: data.sanitizeName,
      is_table_type_transient: data.isTableTypeTransient
    };

    if (data.authType === SnowflakeAuthenticationType.ACCESS_CREDENTIALS) {
      params.db_password = data.password;
    } else {
      params.private_key = data.privateKey.content;
      params.private_key_passphrase = data.passphrase || '';
    }

    if (data.schemaName) {
      params.schema_name = data.schemaName;
    }

    if (data.accountName && data.region) {
      params.account_name = data.accountName;
      params.region_id = data.region;
      params.is_private_link = data.isPrivateLink;
    } else {
      const { accountName, regionId, isPrivateLink } = extractAccountAndRegionFromSnowflakeHostURL(
        data.accountUrl
      );

      params.account_name = accountName;
      params.region_id = regionId || SNOWFLAKE_DEFAULT_REGION_ID;
      params.is_private_link = isPrivateLink;
    }

    return params;
  }

  metaDataResolver(destinationType: string, rawData: any) {
    if (!this._snowflakeAutoFillExperimentService?.isExperimentVariant()) {
      return {};
    }

    const config = rawData.id ? rawData.config : rawData;

    if (!config || Object.keys(config).length === 0) {
      return {};
    }

    const accountName = config.accountName || config.account_name;
    const region = config.region || config.region_id;
    const user = config.user || config.db_user;
    const password = config.password || config.db_password;
    const warehouse = config.warehouseName || config.warehouse;
    const database = config.databaseName || config.db_name;
    const schema = config.schemaName || config.schema_name;

    if (accountName && region && user) {
      if (password) {
        const formData: any = {
          accountName,
          region,
          user,
          password
        };

        const requests$ = [
          DestinationsAPI.getSnowflakeWarehouses(formData)
            .then(res =>
              Promise.resolve(
                (res?.data || []).map(option => ({
                  ...this._addIdToDropdownOption(option?.warehouse || option),
                  isDefaultWarehouse: option?.default_warehouse
                }))
              )
            )
            .catch(() => Promise.resolve([])),
          DestinationsAPI.getSnowflakeDatabases(formData)
            .then(res => Promise.resolve((res?.data || []).map(this._addIdToDropdownOption)))
            .catch(() => Promise.resolve([])),
          DestinationsAPI.getSnowflakeSysAdminPermission(formData)
            .then(res => res?.data)
            .catch(() => Promise.resolve(false))
        ];

        if (database) {
          formData.databaseName = database;

          requests$.push(
            DestinationsAPI.getSnowflakeSchemas(formData, database)
              .then(res => Promise.resolve((res?.data || []).map(this._addIdToDropdownOption)))
              .catch(() => Promise.resolve([]))
          );
        } else {
          requests$.push(Promise.resolve([]));
        }

        return Promise.all(requests$).then(
          ([databaseList, warehouseList, isUserSysAdmin, schemaList]) =>
            Promise.resolve({
              warehouses: databaseList,
              databases: warehouseList,
              isUserSysAdmin,
              schemas: schemaList
            })
        );
      }

      return {
        warehouses: warehouse ? [this._addIdToDropdownOption(warehouse)] : [],
        databases: database ? [this._addIdToDropdownOption(database)] : [],
        schemas: schema ? [this._addIdToDropdownOption(schema)] : []
      };
    }

    return {};
  }

  private _addIdToDropdownOption(option) {
    return {
      id: option,
      name: option
    };
  }
}
