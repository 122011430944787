import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, shareReplay, map } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { RxRequestService } from './rx-request.service';
import { GlobalAction } from '../constants';

export interface AllowedAction {
  type: string;
  display_name: string;
  description: string;
  is_disabled: boolean;
  disabled_reason: string;
}

declare const __IS_H20_RBAC_ENABLED__;

const DummyAllowedActions: AllowedAction[] = [
  {
    type: 'CREATE_DESTINATION',
    display_name: 'Create Destination',
    description: 'Create a new destination for data output',
    is_disabled: false,
    disabled_reason: '',
  },
  {
    type: 'CREATE_PIPELINE',
    display_name: 'Create Pipeline',
    description: 'Create a new data processing pipeline',
    is_disabled: false,
    disabled_reason: '',
  },
]

@Injectable({ providedIn: 'root' })
export class AllowedActionsService {
  private allowedActionsUrl: string;
  private allowedActionsSubject$ = new BehaviorSubject<AllowedAction[]>(null);
  private shouldBypassGlobalActionsCheck = typeof __IS_H20_RBAC_ENABLED__ === 'undefined' || __IS_H20_RBAC_ENABLED__ !== 'True';

  
  

  constructor(
    private appConfig: AppConfig,
    private rxRequestService: RxRequestService
  ) {
    this.allowedActionsUrl = this.appConfig.getAllowedActionsURL();
  }

  getAllowedActions(forceRefresh: boolean = false){
    if (!forceRefresh && this.allowedActionsSubject$.getValue()) {
      return this.allowedActionsSubject$;
    }

    const options = {
      uiOptions: {
        showLoading: false,
        showErrorMsg: false
      }
    };

    if (this.shouldBypassGlobalActionsCheck) {
      this.allowedActionsSubject$.next(DummyAllowedActions);
      return of(DummyAllowedActions);
    }

    return this.rxRequestService.get(this.allowedActionsUrl, options).pipe(
      tap((res) => {
        this.allowedActionsSubject$.next(res.data?.actions || []);
      })
    )
  }

  getAllowedActionsValue(): AllowedAction[] | null {
    return this.allowedActionsSubject$.getValue();
  }

  // Useful for manually updating the allowed actions, possibly from another part of the application.
  syncAllowedActions(allowedActions: AllowedAction[]) {
    this.allowedActionsSubject$.next(allowedActions);
  }

  getActionByType(type: GlobalAction): AllowedAction {
    const actions = this.allowedActionsSubject$.getValue() ?? [];
    return actions.find(action => action.type === type);
  }
}